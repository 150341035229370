import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

export default function BorderBox({ children, style }: Props) {
  return (
    <Box
      my={3}
      p={4}
      borderRadius={5}
      border="solid 2px"
      borderColor="black"
      style={{ ...style }}
    >
      {children}
    </Box>
  );
}
