
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import InstaImage from "@src/components/InstaImage";
import ListBox from "@src/components/ListBox";
import { harajukuRainbowPancakeMenuRainbowMenuTopImage } from "@src/constants/images";
import { murakamiMasanori } from "@src/constants/authors";
export const meta = {
  path: "/media/harajuku/rainbow_pancake_menu",
  title: "【値段あり】レインボーパンケーキのメニューを写真付きで公開！",
  description: "原宿パンケーキの人気店「レインボーパンケーキ」のメニューを、値段と写真付きでまとめました！インスタやネットで調べれば写真はわかるけど、メニューごとの値段がわかるものが見つけられない方も多いのではないかと思います。そこで、レインボパンケーキのメニューを期間限定メニューも合わせてまとめてみました！",
  topImg: harajukuRainbowPancakeMenuRainbowMenuTopImage,
  author: murakamiMasanori,
  tags: ["駅近", "原宿", "パンケーキ", "人気店", "お店の紹介", "デート", "女子会", "メニュー詳細"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿パンケーキの人気店「レインボーパンケーキ」のメニューは、インスタやネットで調べれば写真はわかるけど、`}<strong parentName="p">{`メニューごとの値段`}</strong>{`がわからない。`}</p>
    <p>{`見つけるのに時間がかかると感じたことがある方が多いのではないかと思います。デザートパンケーキだけでなく`}<strong parentName="p">{`期間限定メニュー`}</strong>{`、`}<strong parentName="p">{`お食事パンケーキ`}</strong>{`、`}<strong parentName="p">{`トッピング`}</strong>{`も合わせてメニューを調べてみました！`}</p>
    <p>{`レインボーパンケーキに行く前にチェックして、いろいろ検討してからお越しください！`}</p>
    <hr></hr>
    <h2>{`定番のデザートパンケーキ`}</h2>
    <p>{`レインボーパンケーキで、いつも変わらずに楽しめるデザートパンケーキを、`}<strong parentName="p">{`値段と写真付き`}</strong>{`でご紹介します！
事前にまとまった情報を調べておくと、行った時も焦らずスムーズに選べますよね。
値段は価格がお手頃な順番にまとめてありますので、ご確認ください！`}</p>
    <h3>{`アイスクリーム&メープル(税込：1,150 円)`}</h3>
    <p>{`カナダケベック州の 100%ナチュラルメープルを使用したパンケーキ！
アイスクリームを２種類選ぶことができ、見た目よりもボリューム感があります。`}</p>
    <InstaImage postId="Brj8CplHkSJ" mdxType="InstaImage" />
    <h3>{`焼きリンゴとキャラメル(税込：1,150 円)`}</h3>
    <p>{`キャラメリゼした焼きリンゴにビターテイストの焦がしキャラメルソースをふんだんに使用したパンケーキ！
素材の味を楽しみたい大人な方向けの一品。`}</p>
    <InstaImage postId="B-5nxnhAsH8" mdxType="InstaImage" />
    <h3>{`マカダミアナッツソース(税込：1,250 円)`}</h3>
    <p>{`ハワイでも大人気のマカダミアナッツソースパンケーキをアレンジしたメニューで、レインボーパンケーキで一番の人気メニュー！`}</p>
    <InstaImage postId="B-84KF6plTW" mdxType="InstaImage" />
    <h3>{`生チョコ&オレンジ(税込：1,250 円)`}</h3>
    <p>{`オレンジリキュールを使用した自家製の生チョコとオレンジのシロップ漬けを使用した大人なパンケーキ！
リキュールの香りが濃厚に漂う一品になっています。`}</p>
    <InstaImage postId="B-ZQJ3-lrpY" mdxType="InstaImage" />
    <h3>{`季節のフルーツ(税込：1,350 円)`}</h3>
    <p>{`レインボーパンケーキが仕入れる旬のフルーツをふわふわのクリーに包みこんだ高さのあるパンケーキ！
さっぱりとしたパンケーキを食べたい方におすすめ！`}</p>
    <InstaImage postId="B_SSlHSBJWP" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`期間限定のデザートパンケーキ`}</h2>
    <p>{`レインボーパンケーキは期間限定で特別メニューを出しています！今回ご紹介するのは、既に販売が終わってしまっているものですが、定期的に限定のメニューを出しているので、行く前は`}<strong parentName="p">{`最新情報をチェック`}</strong>{`してからお越しください！`}</p>
    <h3>{`マカダミアナッツいちご 2020(税込：1,600 円)`}</h3>
    <p>{`4 月~5 月にかけての期間限定メニューで、1 日に 30 食しか提供していないパンケーキメニュー！
また出るのが楽しみな一品！`}</p>
    <InstaImage postId="B9tkSiIg8Ig" mdxType="InstaImage" />
    <h3>{`絶品焼き芋！のパンケーキ「こがね」(税込：1,600 円)`}</h3>
    <p>{`2020/1.11~1 月下旬の期間で食べられた１日に 30 食限定のパンケーキメニュー。
種子島熊野農園安納芋を使用した濃厚なパンケーキでした！`}</p>
    <h3>{`西武池袋店限定！いちごのフルーツサンド(税込：1500 円)`}</h3>
    <p>{`店舗限定で、いちごがふんだんに使われていたフルーツサンドが美しい。
パンケーキではないですが、こういったメニューも食べてみたいですよね！`}</p>
    <InstaImage postId="B7fqJ2ep6hU" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`トッピング`}</h2>
    <p>{`せっかく来たから「好きなトッピングがしたい」「一枚くらい多くパンケーキが食べたい！」と、メニューを考えているとと思ってしまいますよね。
レインボーパンケーキではお財布に優しい値段でトッピングができるメニューがたくさんあるので、ご紹介します！`}</p>
    <h3>{`トッピングメニュー`}</h3>
    <ListBox list={["ホイップクリーム(100円): パンケーキにクリームを塗って食べるとおいしさ倍増！", "チョコソース(100円): 見た目も変わるチョコソースで、普段と違ったパンケーキを！", "アイス(バニラ・ラム)(100円): 季節のフルーツパンケーキなど、アイスクリームがついていないパンケーキにトッピングがおすすめ！", "100%ナチュラルメープルシロップ(200円): 「パンケーキといえば、メープルシロップ！」という方は、天然のメープルシロップをふんだんに。", "パンケーキ一枚増加(250円): 甘いものならどれだけでも大丈夫！という方は、一枚増量のトッピングを！"]} mdxType="ListBox" />
    <hr></hr>
    <h2>{`お食事パンケーキ`}</h2>
    <p>{`レインボーパンケーキ では、甘いパンケーキ以外にも「ベーコン」や「アボカド」などを使った塩っけのあるパンケーキも注文できます！
甘いのがどちらかと苦手だけど、友達とパンケーキは食べに行きたい！という方は要チェック！！
今回は、レインボーパンケーキで一番の人気メニューをご紹介させていただきます。`}</p>
    <h3>{`サーモン&アボカド(1,350 円)`}</h3>
    <p>{`アボカドの甘みとサーモンの塩っけが甘いパンケーキをしっかり引き立ててくれる。
甘いのが苦手な方でも挑戦できるパンケーキになっています！`}</p>
    <InstaImage postId="B82c6hqBEEr" mdxType="InstaImage" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`レインボーパンケーキのメニューを期間限定のものを合わせてご紹介させていただきました！
まとまった情報サイトがなかなか見つからないと思いますので、また気になった場合はこちらのサイトを参考にして使ってみてください！`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;