
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import BorderBox from "@src/components/BorderBox";
import GoogleMap from "@src/components/GoogleMap";
import InstaImage from "@src/components/InstaImage";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import { murakamiMasanori } from "@src/constants/authors";
import { harajukuRaninbowPancakeTopImage } from "@src/constants/images";
import { meta as pancakePageMeta } from "./pancake.mdx";
import { meta as rainbowPancakeMenuPageMeta } from "./rainbow_pancake_menu.mdx";
export const meta = {
  path: "/media/harajuku/rainbow_pancake",
  title: "”原宿×パンケーキ”ならこの店、「レインボーパンケーキ 」の人気の秘密に迫る",
  description: "パンケーキの激戦区原宿で人気が衰えないレインボーパンケーキ。レインボーパンケーキ　独特のコンセプト、パンケーキの作り方、予約システムなど、お客様の立場を元にした 工夫があります。それが原宿のパンケーキでも人気のお店に、そして愛され続ているのだと思います。そんな、レインボーパンケーキの秘密に迫っていきます。",
  topImg: harajukuRaninbowPancakeTopImage,
  author: murakamiMasanori,
  tags: ["駅近", "原宿", "パンケーキ", "人気店", "お店の紹介", "デート", "女子会"]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Text = makeShortcode("Text");
const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`パンケーキの激戦区原宿で人気が衰えないレインボーパンケーキ。
レインボーパンケーキはオーナーが会社員の時にハワイで感じた日本人の笑顔がきっかけで、その独特のコンセプトを作ったと言われています！`}</p>
    <p>{`レインボーパンケーキのオーナーがハワイで感じたことは！？レインボーパンケーキの虹はなぜ６色なの！？行列に並ばずに入れる予約システムは！？といった秘密に迫っていきたいと思います！`}</p>
    <hr></hr>
    <h2>{`レインボーパンケーキのコンセプトはハワイでの出会いから`}</h2>
    <h3>{`オーナーがハワイで発見した笑顔の秘密`}</h3>
    <p>{`オーナーが日本にまだパンケーキがない時代に、ハワイの有名パンケーキのおお店で日本人観光客がたくさん並んで、パンケーキを幸せそうに笑顔で楽しんでいる人を見たことが全ての始まり。
ハワイにいた日本人のように、「笑顔を日本の人たちにも届けたい」という思いで、開業したそうなんです。`}</p>
    <InstaImage postId="BlhOfjbgiIn" mdxType="InstaImage" />
    <h3>{`レインボーパンケーキのレインボーはなぜ６色？`}</h3>
    <p>{`ハワイの人たちの笑顔の源であるレインボーですが、ハワイでは虹が７色ではなく６色で描かれるそうです。
そんなハワイでの体験や想いを込めて、レインボーパンケーキのコンセプトが作られていることを知ると豆知識みたいで、ワクワクしますよね。`}</p>
    <InstaImage postId="B8n9EcPgzcb" mdxType="InstaImage" />
    <h3>{`レインボーの外観は入る前からワクワク`}</h3>
    <p>{`原宿のレインボーパンケーキの店舗は、外からでもすぐにわかるように、ガラスの外壁にレインボーがかかっています！
「あれだ！見つけた！」という感じで、外観のレインボーを見つけると、恋人や友人との話も弾むし、入る前からワクワクしますよね。`}</p>
    <InstaImage postId="BnIpNHkn4Qs" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`パンケーキのおいしさの秘密`}</h2>
    <h3>{`パンケーキなのに「アレ」を使わない！？`}</h3>
    <p>{`レインボーパンケーキのパンケーキの美味しさの秘密は、食材へのこだわりがあります。
パンケーキの生地には、他のパンケーキのお店なら使っているだろう`}<strong parentName="p">{`バター`}</strong>{`を使わず、なんと`}<strong parentName="p">{`低脂肪の牛乳`}</strong>{`を代わりに使っているのだそう！
だから、レインボパンケーキのメニューは、バターを使わなくてもしっかりコクのあるパンケーキになっているのだと思います！`}</p>
    <InstaImage postId="B98i916pE7K" mdxType="InstaImage" />
    <h3>{`元祖ふわふわの秘密`}</h3>
    <p>{`レインボーパンケーキ は生地の焼き上げ方に、そのふわふわの秘密があるそうなんです。`}</p>
    <p>{`オーダーを受けてから一つずつ丁寧に焼き上げる生地ですが、実は`}<strong parentName="p">{`二度焼き`}</strong>{`するそうで、鉄板で焼き上げた後に、オーブンでさらに焼き上げます。
こうすることで、ふわふわなのに生地の食感もしっかり感じられるパンケーキに仕上がるそうなんです！`}</p>
    <InstaImage postId="B9bmTdfHwuQ" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`行列に並ばず入れる予約システムとは？`}</h2>
    <h3>{`原宿には一階席がある！？`}</h3>
    <p>{`激戦区の原宿パンケーキをが、「行列に並ばないといけない」と思ってしまいますよね。
待つことは大変だという方、それでもパンケーキが食べたいという方には、レインボーパンケーキの予約システムがあるので、ご紹介させてください！`}</p>
    <h3>{`個室の予約ができる１階席では、専用のパンケーキも！？`}</h3>
    <p>{`定員数の制限と使用料がかかりますが、どうしても待ちたくない！という人にはオススメです！他のお客様を気にせずにのんびりしながら、パンケーキが食べられます！`}</p>
    <InstaImage postId="B8vd-nulnQ1" mdxType="InstaImage" />
    <BorderBox mdxType="BorderBox">
  <Text as="p" mdxType="Text">
    <Text as="span" fontWeight="bold" mdxType="Text">
      最大定員数：8名
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      個室使用料：2000円
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      使用時間：90分
    </Text>
  </Text>
    </BorderBox>
    <h3>{`１ヶ月前から開店時間のみ電話予約ができる！`}</h3>
    <p>{`開店時間の 10 時からだと予約ができるようになっています。
レインボーパンケーキにずっと行きたいと思っていたけど、並ぶのは大変という方、いく日がもう決まっているという方は、事前に電話予約をすれば待たずに入れます！`}</p>
    <hr></hr>
    <h2>{`レインボーパンケーキ の人気メニューがこちら`}</h2>
    <h3>{`三段重ねもできる「マカダミアナッツソースパンケーキ」`}</h3>
    <InstaImage postId="B9zASYODrX5" mdxType="InstaImage" />
    <p>{`ミルキーでまろやかな味わいのマカダミアナッツソースで、ハワイのお店よりも食べやすい味になっています！
パンケーキの枚数もトッピングができるようになっているらしく、最近は女性の方でも三枚重ねで食べる方が多いのだとか？`}</p>
    <h3>{`他にもこんなメニューがあります！`}</h3>
    <h4>{`「季節のフルーツパンケーキ」`}</h4>
    <InstaImage postId="B9J-xXJpYoF" mdxType="InstaImage" />
    <h4>{`「いちごとハニーのフロマージュ」`}</h4>
    <InstaImage postId="B9Gz_MQAD04" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`原宿からのアクセス`}</h2>
    <p>{`原宿と明治神宮駅から徒歩５分程度の距離にあります！`}</p>
    <GoogleMap lat={35.6694583} lng={139.7050774} mdxType="GoogleMap" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`原宿パンケーキの名店、レインボーパンケーキの秘密に迫ってきました！レインボーパンケーキならではのこだわりや工夫があったと思いますので、足を運びたい方はこの記事を参考にいただけますと嬉しいです。`}</p>
    <p>{`レインボーパンケーキのメニューに関してはこちら`}</p>
    <LinkWithThumbnail meta={rainbowPancakeMenuPageMeta} mdxType="LinkWithThumbnail" />
    <p>{`また、原宿にはレインボーパンケーキ以外にもおしゃれなパンケーキ屋さんがいっぱいあります。下記の記事では、その中でも`}<strong parentName="p">{`予約可能な原宿の人気パンケーキ店をまとめています`}</strong>{`ので、是非ご覧ください！`}</p>
    <LinkWithThumbnail meta={pancakePageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;