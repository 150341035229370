import React from "react";
import { List, ListItem } from "@chakra-ui/react";
import BorderBox from "./BorderBox";
import Text from "./Text";

interface Props {
  list: string[];
  as?: "ul" | "ol";
}

export default function ListBox({ list, as }: Props) {
  const items = list.map((li) => (
    <ListItem key={li} my={2}>
      <Text as="span">{li}</Text>
    </ListItem>
  ));
  return (
    <BorderBox>
      <List as={as || "ul"} styleType={as === "ol" ? "decimal" : "disc"}>
        {items}
      </List>
    </BorderBox>
  );
}
