
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import InstaImage from "@src/components/InstaImage";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import { murakamiMasanori } from "@src/constants/authors";
import { harajukuPancakeTopImage } from "@src/constants/images";
import { meta as rainbowPancakePageMeta } from "./rainbow_pancake.mdx";
import { meta as uzunaomomPageMeta } from "./uzunaomom.mdx";
import { meta as rainbowPancakeMenuPageMeta } from "./rainbow_pancake_menu.mdx";
export const meta = {
  path: "/media/harajuku/pancake",
  title: "【個人店】予約ができる原宿・表参道のパンケーキ人気店４選",
  description: "原宿・表参道でパンケーキが食べたい。でもお店に行く時はいつも大行列で待てなかったり、待つのに疲れた経験はありませんか？そんなあなたに原宿・表参道の人気パンケーキが食べられて、予約もできるお店を厳選してご紹介！予約ができれば、待ち時間のストレスもなく美味しいパンケーキが食べられます。",
  topImg: harajukuPancakeTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "パンケーキ", "人気店", "まとめ記事", "1人メシ", "女子会", "デート"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿・表参道のパンケーキといっても、原宿・表参道エリアは、パンケーキ激戦区なのでなかなかお店を選べない。お店も選べないし、`}<strong parentName="p">{`人気のパンケーキ屋さんに行くと、行列ができていて待たないといけない。`}</strong></p>
    <p>{`さらに、いつも行く定番のお店とは違うお店でパンケーキを食べたい！そんな悩みを抱える方も多いのではないでしょうか。そんなあなたのために、`}<strong parentName="p">{`原宿・表参道で、予約ができるパンケーキ店を厳選して選びました！`}</strong></p>
    <p>{`原宿・表参道でパンケーキを食べに行く時は、是非参考にしていただき、予約をして、ストレスない素敵な休日を過ごしてください！`}</p>
    <h2>{`【原宿パンケーキ】レインボーパンケーキ`}</h2>
    <h3>{`原宿パンケーキで絶対に外せないパンケーキの名店。`}</h3>
    <p>{`レインボーパンケーキは、`}<strong parentName="p">{`“笑顔の源”となるスイーツを、という想いから生まれたパンケーキ店。`}</strong>{`
レインボーが６色なのは、ハワイの虹が６色で表現されることに由来しているそうです。`}</p>
    <h3>{`マカダミアナッツパンケーキ(1,250 円)`}</h3>
    <InstaImage postId="BldPLhYDRWQ" mdxType="InstaImage" />
    <p>{`パンケーキの中で一番の人気メニューがこのマカダミアナッツパンケーキ！
他のパンケーキと比べても値段は安く、手が出しやすいですよね。`}</p>
    <h3>{`いちごとハニーフロマージュのパンケーキ(1,500 円)`}</h3>
    <InstaImage postId="B8IYiL1JFqB" mdxType="InstaImage" />
    <p>{`ショートケーキのように見えるパンケーキは、インスタに上げたくなるような一品！
イチゴの季節になると食べたくなりますよね。`}</p>
    <h3>{`季節フルーツパンケーキ(1350 円)`}</h3>
    <InstaImage postId="B_SSlHSBJWP" mdxType="InstaImage" />
    <p>{`年中食べることができる季節のフルーツがのったパンケーキ！
他のパンケーキとは違って縦に高く伸び、季節のフルーツがのっているのが特徴！`}</p>
    <p>{`普段は大行列ができているため、なるべく予約して行くことをオススメします！`}</p>
    <LinkWithThumbnail meta={rainbowPancakePageMeta} mdxType="LinkWithThumbnail" />
    <LinkWithThumbnail meta={rainbowPancakeMenuPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【原宿パンケーキ】ウズナ オムオム`}</h2>
    <p>{`原宿にあるパンケーキ屋さんで、月替りでパンケーキが変わっていくのが特徴！
原宿のキャットストリートに店舗を構えており、穴場的な存在！パンケーキだけでなく、店内もおしゃれでインスタ映えするアイテムがたくさん！`}</p>
    <h3>{`いちごとピスタチオカスタードのパンケーキ(1,450 円)`}</h3>
    <InstaImage postId="Bh1PF5SlCi8" mdxType="InstaImage" />
    <p>{`2020 年の 2 月の期間限定メニューだったこちらのパンケーキは、
小ぶりだけど割と量があると感じるそうです！
インスタにあげたくなる一品！`}</p>
    <h3>{`ホットチョコレートソースがけ カシスと木苺のホリデースペシャル(1,450 円)`}</h3>
    <InstaImage postId="BcJSbMtlBVv" mdxType="InstaImage" />
    <p>{`パンケーキが全く見えないくらいチョコレートがかかったこのメニューは、
写真も撮りたいし、インスタにもあげたいし、絶対注文したい一品！`}</p>
    <h3>{`桜のパンケーキ(1,450 円)`}</h3>
    <InstaImage postId="B-9IZmlgyFH" mdxType="InstaImage" />
    <p><strong parentName="p">{`自家製の桜のアイスが乗った、春にぴったりなメニュー！`}</strong>{`
スプーンの形をしたクッキーなど、サイドメニューもボリュームたっぷりの一品を、
是非楽しんでください！`}</p>
    <p>{`こちらのお店も普段は行列ができやすいので、予約をして行くことをオススメします！`}</p>
    <LinkWithThumbnail meta={uzunaomomPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【表参道パンケーキ】木と水と土と 表参道店`}</h2>
    <p><strong parentName="p">{`和歌山発祥のこのお店は、その名の通り自然を意識したメニューがたくさん！`}</strong></p>
    <p>{`原宿・表参道だと女性や外国人の方も多く、安心して食べられるような、グルテンフリーのメニューなども揃えているのだとか！`}</p>
    <p>{`東京だと原宿・表参道のエリアしかないので、是非食べに行ってください！`}</p>
    <h3>{`クリームブリュレパンケーキ(1,000 円)`}</h3>
    <InstaImage postId="BnFa6qyAoBO" mdxType="InstaImage" />
    <p>{`パンケーキのの表面をキャラメリゼで仕上げた`}<strong parentName="p">{`新食感`}</strong>{`が楽しめる一品になっています！
値段もパンケーキの中ではリーズナブルなので、手が出しやすい一品になっています！`}</p>
    <h3>{`宇治抹茶パンケーキ(1,000 円)`}</h3>
    <InstaImage postId="B-4ErpolyFH" mdxType="InstaImage" />
    <p>{`生地に京都の宇治の抹茶を使用しており、黒蜜などと一緒に食べるとまさに和風パンケーキ！`}</p>
    <h3>{`バナナキャラメルパンケーキ(1,100 円)`}</h3>
    <InstaImage postId="B-uIo9Ln3oX" mdxType="InstaImage" />
    <p>{`米粉で作った、体に優しいパンケーキになります。
定番のメニューが食べたい方、健康を気遣う方はこちらがオススメです！`}</p>
    <p>{`こちらも行列で待ち時間が長いことが予想されるので、予約して行くことをオススメします！`}</p>
    <hr></hr>
    <h2>{`【原宿パンケーキ】ミカサデコ＆カフェ神宮前`}</h2>
    <p>{`3/14 に店舗がグランドオープンし、いつも行列ができているお店です。`}</p>
    <h3>{`春の苺パンケーキ`}</h3>
    <InstaImage postId="B9sfBWlgO8z" mdxType="InstaImage" />
    <p>{`春限定のメニューになりますが、季節の苺をふんだんに使ったパンケーキになります！
写真からも伝わってくるふわふわな生地を楽しみたいですよね。`}</p>
    <h3>{`ふわふわキャラメルパンケーキ`}</h3>
    <InstaImage postId="B8AO8-mAefG" mdxType="InstaImage" />
    <p>{`冬限定のメニューになりますが、生地にもキャラメルの色が付いているパンケーキ。
これは食べずにはいられない一品ですよね。`}</p>
    <h3>{`かぼちゃのブリュレパンケーキ`}</h3>
    <InstaImage postId="B3_ay3yg0gJ" mdxType="InstaImage" />
    <p>{`ハロウィーン限定のメニューになりますが、かぼちゃのブリュレがふんだんに使われているパンケーキ！`}</p>
    <p><strong parentName="p">{`クッキーやアイスクリームの色もハロウィーンを意識しており、思わずインスタにアップしたくなりますよね。`}</strong></p>
    <p>{`開店したばかりということもあって、連日行列ができており、1 時間以上は待たないといけないと思うので、
こちらも予約して行くことをおススメします！`}</p>
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`いかがでしたでしょうか！？
いつも行っているチェーンのパンケーキのお店とは異なり、予約ができる個人の人気店に厳選してご紹介しました！
予約をして、ストレスなくおいしいパンケーキを食べてみてください！`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;