
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import GoogleMap from "@src/components/GoogleMap";
import InstaImage from "@src/components/InstaImage";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import { murakamiMasanori } from "@src/constants/authors";
import { harajukuUzunaomomTopImage } from "@src/constants/images";
import { meta as pancakePageMeta } from "./pancake.mdx";
export const meta = {
  path: "/media/harajuku/uzunaomom",
  title: "【穴場】1人でも入れる原宿パンケーキの名店ウズナオムオム(uzuna omom)",
  description: "原宿でおいしいパンケーキが食べたい！でも行列に並んだり、待ち時間が長のは大変ですよね。1人だと待つことは難しいし、ゆっくりくつろいでパンケーキを食べることもできない。パンケーキは食べたいけど、穴場のパンケーキを知らない。そういった悩みを抱えたことはないでしょうか。そんなあなたに、原宿パンケーキで1人でも入れる穴場のウズナオムオム(uzuna omom))をご紹介させていただきます！",
  topImg: harajukuUzunaomomTopImage,
  author: murakamiMasanori,
  tags: ["穴場", "カフェ", "有名店", "行列店", "パンケーキ", "おしゃれな内装", "人気店", "お店の紹介", "デート", "女子会", "食べログ高評価"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿はパンケーキの激戦区。どこに行っても人気のお店は行列で、待ち時間がないのは大変。ゆっくり 1 人でパンケーキを食べたいなって思った時に、ゆっくりできる穴場を知らないので諦めてしまう。`}</p>
    <p>{`入れたとしても、ゆっくりくつろいでパンケーキを食べることもできない。そういった悩みを抱えたことはないでしょうか。
そんなあなたに、ウズナオムオム(uzuna omom)をご紹介させていただきます！`}</p>
    <p>{`原宿のパンケーキで 1 人でも入れる穴場のお店で、絶品パンケーキが食べられることで有名なウズナオムオム(uzuna omom))。
せっかく美味しいパンケーキを食べたいからこそ、穴場であるウズナオムオム(uzuna omom))のことを伝えられたと思う。`}</p>
    <hr></hr>
    <h2>{`ウズナオムオム(uzuna omom)の本当の意味は！？`}</h2>
    <p>{`ウズナオムオム(uzuna omom)という言葉は聞いたことないし、どんな意味なんだろう？って思った方も多いのではないのでしょうか。
実は逆から読むのが本当の読み方になるんです！`}</p>
    <p><strong parentName="p">{`逆から読むと`}</strong>{`、`}<strong parentName="p">{`MOMO ANZU`}</strong>{`、`}<strong parentName="p">{`桃　杏`}</strong>{`になります！
ウズナオムオム(uzuna omom)のオーナーが、`}<strong parentName="p">{`桃　杏`}</strong>{`が好きだという背景からできているそうです。`}</p>
    <p>{`こういうのを知ると、おもしろいですよね！`}</p>
    <hr></hr>
    <h2>{`マンションの一室にある原宿パンケーキの穴場！`}</h2>
    <p>{`ウズナオムオム(uzuna omuomu)は、原宿で人気のお店とは異なり、キャットストリートの裏通りにあります。
そのため超人気店にもかかわらず、並ばずにパンケーキが食べられる原宿でも珍しいお店になっています。`}</p>
    <h3>{`道路にウズナオムオム(uzuna omuomu)と書いた看板`}</h3>
    <p>{`ウズナオムオム(uzuna omuomu)と書いた看板がすぐに見つけられると思います！`}</p>
    <InstaImage postId="B1czxXwnspZ" mdxType="InstaImage" />
    <h3>{`外観`}</h3>
    <p>{`外観はマンションの一室と思えないようなおしゃれさ！`}</p>
    <InstaImage postId="B52wD8QlqJ8" mdxType="InstaImage" />
    <h3>{`店内はこじんまりとして落ちついていて穴場感満載！`}</h3>
    <p>{`こじんまりとしたアットホームな雰囲気なので、お一人様でも来店できます！
ふとパンケーキが食べたくなったときは、原宿でも 1 人でいけるパンケーキ屋さんになっています！`}</p>
    <InstaImage postId="Bv3Bg6TAYcA" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`予約ができて並ばずにパンケーキが食べられる`}</h2>
    <p>{`原宿のパンケーキは激戦区。穴場といえども曜日や時間帯によっては、行列になることもあります。
実は、ウズナオムオム(uzuna omuomu)は予約ができるようになっているので、可能であれば当日でも予約していくことをオススメします！`}</p>
    <p><strong parentName="p">{`予約はコチラから`}</strong>{`：03-3499-2337`}</p>
    <hr></hr>
    <h2>{`穴場なのに超人気店なのは、絶品のパンケーキ`}</h2>
    <p>{`ウズナオムオム(uzuna omuomu)は、原宿の激戦区の中でも、食べログの評価は 3.6。穴場であっても超人気店なのは、絶品のパンケーキになります！
インスタにもたくさん写真がアップされているので、パンケーキをご紹介します！`}</p>
    <h3>{`月替りのパンケーキ`}</h3>
    <p>{`ウズナオムオム(uzuna omuomu)は月替りでパンケーキが楽しめます！
毎月違うパンケーキが食べられるのは、なんだかワクワクしますよね！`}</p>
    <InstaImage postId="RFbVVeBQsu" mdxType="InstaImage" />
    <InstaImage postId="B8bZXMSJQrp" mdxType="InstaImage" />
    <InstaImage postId="B6rWMgPggmJ" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`アクセス`}</h2>
    <p>{`原宿と明治神宮駅から徒歩５分程度の距離にあります！`}</p>
    <GoogleMap lat={35.6570313} lng={139.6926224} mdxType="GoogleMap" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`原宿の穴場のパンケーキ、ウズナオムオム(uzuna omuomu)についてご紹介してきましたが、いかがでしたでしょうか？`}</p>
    <p><strong parentName="p">{`1 人でパンケーキが食べたいとき、なるべく並びたくないとき、ゆっくりくつろぎたいときなど、状況に合わせて穴場のウズナオムオム(uzuna omuomu)に足を運んでみてください！`}</strong></p>
    <p>{`また、原宿にはウズナオムオム以外にもおしゃれなパンケーキ屋さんがいっぱいあります。下記の記事では、その中でも`}<strong parentName="p">{`予約可能な原宿の人気パンケーキ店をまとめています`}</strong>{`ので、是非ご覧ください！`}</p>
    <LinkWithThumbnail meta={pancakePageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;